<template>
  <div class="score-block text-center">
    Score: {{ score }}
    <span v-show="combo > 0"> combo:{{ combo }} </span>
  </div>
</template>

<script>
export default {
  name: "scoreBoard",
  props: {
    score: {
      default: 0,
      type: Number
    },
    combo: {
      default: 0
    }
  }
};
</script>

<style scoped></style>
