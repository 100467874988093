<template>
  <div class="full-page">
    <div
      data-vimeo-url="https://vimeo.com/432853107/e190af5c7f"
      :data-vimeo-width="width"
      :id="element_id"
      autoplay
      @click="play"
    ></div>
  </div>
</template>

<script>
import { sendMessage } from "../utils/WebSocket";
import WebSocketMixin from "../Mixins/WebSocketMixin";
import TeamFromVuex from "../Mixins/TeamFromVuex";
import debounce from "lodash.debounce";
import StageFromMixin from "../Mixins/StageFromMixin";
import player from "@vimeo/player";
import { mapGetters } from "vuex";

export default {
  name: "danceVideo",
  mixins: [WebSocketMixin, TeamFromVuex, StageFromMixin],
  mounted() {
    this.width = window.innerWidth;
    this.$nextTick(() => {
      this.loadVideo();
    });
  },
  computed: {
    ...mapGetters("Video", ["getVideoPath", "getVimeoId"]),
    element_id() {
      return "vimeo-dance";
    },
    stage_2_danceFinish(){
      return this.flag('stage_2_danceFinish');
    }
  },
  watch: {
    stage_2_danceFinish (val) {
      if (val) {
        this.$router.replace('danceResult');
      }
    },
  },
  data() {
    return {
      width: 300,
      igVideo: null
    };
  },
  methods: {
    play() {
      this.igVideo?.play();
    },
    loadVideo() {
      this.igVideo = new player(this.element_id, {
        autoplay: true,
        controls: true
      });
      this.igVideo.on("timeupdate", event => {
        this.timeSync(event);
      });
      this.igVideo.on("play", () => {
        this.begin();
      });
      this.igVideo.on("ended", () => {
        let messageData = {
          method: "DanceScore",
          token: this.token,
          score: 0,
          max_combo: 0
        };
        sendMessage(messageData);
        messageData = {
          method: "DanceVideoEnd",
          token: this.token,
          time: Date.now()
        };
        sendMessage(messageData);

        this.$router.replace("/danceResult");
      });
    },
    begin() {
      let messageData = {
        method: "DancePlayer",
        token: this.token,
        time: Date.now()
      };
      sendMessage(messageData);
    },
    timeSync: debounce(
      function(event) {
        let messageData = {
          method: "DanceTimeSync",
          token: this.token,
          event: event
        };
        sendMessage(messageData);
      },
      4000,
      { maxWait: 1000 }
    )
  }
};
</script>

<style scoped lang="scss">
.full-page video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
