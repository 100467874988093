<template>
  <div class="result-list text-center">
    <h2 class="text-center mb-4 text-white">{{ title }}</h2>
    <ul class="list-unstyled rank-list font-14">
      <li class="d-flex align-items-center" v-for="(user, index) in order_rankList" :key="user.id">
        <div class="mr-2">{{ index + 1 }}</div>
        <div class="cha-circle circle mr-3">
          <img v-bind:src="showAvatar(user.avatar)" alt="" />
        </div>
        <div class="team-name text-truncate mr-3 text-left">
          {{ user.name }}-
          <template v-if="user.nickname.length < 6">{{ user.nickname }}</template>
          <template v-else>{{ user.nickname.slice(0, 5) + "..." }}</template>
        </div>
        <div>
          <div>最高分:{{ user.highscore }}</div>
          <div>本次分數:{{ user.score }}</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import Avatar from "@/Mixins/Avatar";

export default {
  name: "resultList",
  mixins: [Avatar],
  props: {
    title: {
      default: "",
      type: String
    },
    rankList: {
      default: () => []
    }
  },
  computed: {
    order_rankList() {
      let list = Object.values(this.rankList) || [];
      return list.sort((a, b) => {
        return b.highscore - a.highscore;
      });
    }
  },
  methods: {
    getAvatar(row) {
      let avatar = row?.user?.avatar ?? "";

      if (avatar?.includes("default")) {
        avatar = row?.character?.picture;
      }
      if (avatar?.includes("profile")) {
        avatar = row?.character?.picture;
      }
      return this.showAvatar(avatar || row?.character?.picture || row?.picture || row?.avatar);
    }
  }
};
</script>

<style scoped>
.result-list {
  padding-top: 100px;
}
</style>
