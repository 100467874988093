<template>
  <div class="fill-page-setting position-absolute">
    <transition name="fade">
      <div class="hint-content position-absolute" v-show="showHint" @click="goHint">
        此階段需要所有玩家都做出選擇以後，方能進行下一步。如果有隊伍成員不想繼續遊玩的情況，請點擊齒輪圖示，將該成員由隊伍中剔除。注意！被剔除的成員將無法參與後續的遊戲流程，也無法再重回遊戲
      </div>
    </transition>
    <!-- <a role="button" v-show="!hideBtn" class="position-relative">
      <svg class="btn-icon-28">
        <use xlink:href="#icon-user-setting" class="fill-white"></use>
      </svg>
    </a>
    <a role="button" v-show="!hideBtn" class="ml-3" @click="goHint">
      <svg class="btn-icon-24">
        <use xlink:href="#icon-intro" class="fill-white"></use>
      </svg>
    </a> -->
  </div>
</template>

<script>
export default {
  name: "fullpageInfo",
  props: {
    hideBtn: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      showHint: false
    };
  },
  methods: {
    goHint() {
      this.showHint = !this.showHint;
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/__var";

.hint-content {
  width: 200px;
  padding: 20px;
  background: #fff;
  right: 0;
  top: 32px;
  border-radius: 10px;
  z-index: 99;
  @include default-btn-shadow;
}
</style>
