import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("Stage", ["stages", "locations", "flags", "flag", "localFlag"])
  },
  methods: {
    ...mapActions("Stage", ["setFlag", "sendStageMessage", "lockStage", "unlockStage", "setLocalFlag"])
  }
};
