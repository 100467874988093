import { render, staticRenderFns } from "./resultList.vue?vue&type=template&id=5d28c638&scoped=true&"
import script from "./resultList.vue?vue&type=script&lang=js&"
export * from "./resultList.vue?vue&type=script&lang=js&"
import style0 from "./resultList.vue?vue&type=style&index=0&id=5d28c638&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d28c638",
  null
  
)

export default component.exports