<template>
  <div class="position-relative full-page dance-stand-bg">
    <logoutBtn position="upper-left"></logoutBtn>
    <teamWating></teamWating>
    <fullpageInfo></fullpageInfo>
    <div
      class="hint-circle d-flex justify-content-center align-items-center position-absolute"
      style="width: 7vw; right: 5vw;top: 5vw;"
      @click="showHint"
    >
      <img
        src="@/assets/images/icon-help.svg"
        style="filter: invert(99%) sepia(0%) saturate(3365%) hue-rotate(254deg) brightness(119%) contrast(100%);"
        alt=""
      />
    </div>
    <div v-if="isShowHint" style="padding: 25px 10px;width:calc(100vw - 50px);" class="app-modal">
      <p>如果有隊友沒有順利一起進入此畫面，請點擊「同步」將隊友同步至此畫面</p>
      <div
        style="display:inline-block; width:45%; margin-right:10%;"
        class="btn btn-outline-primary btn-block"
        @click="closeHint"
      >
        返回
      </div>
      <div
        style="display:inline-block; width:45%; margin-top:0;"
        class="btn btn-outline-primary btn-block"
        @click="doAction"
      >
        同步
      </div>
    </div>
    <gamePrepare :title="title" :btnLink="btnLink"></gamePrepare>
    <a
      v-if="flag('stage_2_danceFinish')"
      @click="endgame"
      class="position-fixed page-btn d-flex justify-content-center align-items-center"
    >
      看結果
    </a>
  </div>
</template>

<script>
import fullpageInfo from "@/views/part/fullpageInfo";
import gamePrepare from "@/views/part/gamePrepare";
import logoutBtn from "@/views/part/logoutBtn";

import { mapActions, mapGetters } from "vuex";
import UserFromVuex from "../Mixins/UserFromVuex";
import WebSocketMixin from "../Mixins/WebSocketMixin";
import TeamFromVuex from "../Mixins/TeamFromVuex";
import ModalMixin from "../Mixins/ModalMixin";
import teamWating from "@/views/part/teamWating";
import { sendMessage } from "@/utils/WebSocket";
import StageFromMixin from "@/Mixins/StageFromMixin";

export default {
  name: "danceStandBy",
  mixins: [UserFromVuex, WebSocketMixin, TeamFromVuex, ModalMixin, StageFromMixin],
  components: {
    fullpageInfo,
    gamePrepare,
    teamWating,
    logoutBtn
  },
  computed: {
    ...mapGetters("Dance", ["players", "dancers", "ready_list"]),
    ...mapGetters("Team", ["isLeader"]),
    allReady() {
      let length = this.ready_list.length;
      return length == this.members.length;
    }
  },
  mounted() {
    this.resetStartTimestamp();
  },
  watch: {
    ready_list(val) {
      if (val.length == this.team.member.length) this.hint = "等待隊長開始遊戲";
    }
  },
  data() {
    return {
      title: "",
      isShowHint: false,
      btnLink: [
        {
          name: "來練舞吧",
          // link: "dance",
          event: this.choseRole("dancer"),
          users: () => this.dancers
        },
        {
          name: "展示影片",
          // link: "danceVideo",
          event: this.choseRole("player"),
          users: () => this.players
        },
        {
          name: "開始遊戲",
          // link: "danceVideo",
          event: this.begin,
          users: []
        }
      ]
    };
  },
  methods: {
    ...mapActions("Dance", ["danceChoseRole", "start", "resetStartTimestamp"]),
    choseRole(role) {
      return () => {
        this.danceChoseRole({
          role,
          character: this.character
        });
      };
    },
    showHint: function() {
      this.isShowHint = true;
    },
    closeHint: function() {
      this.isShowHint = false;
    },
    doAction: function() {
      this.isShowHint = false;
      const data = {
        method: "EnterStage",
        token: this.token,
        location: {
          link: "danceStandBy"
        }
      };
      sendMessage(data);
    },
    begin() {
      if (this.players.length < 1) {
        this.showModal("至少要有一個人播放影片");
        return;
      }
      if (this.isLeader && this.allReady) {
        if (!this.allMemberOnline) {
          this.showModal("請確認所有人都在線上才能開始遊戲");
          return;
        }
        this.start();
        return;
      }
      this.showModal("有人還沒選唷");
    },
    endgame() {
      this.$router.replace("/danceResult");
    }
  }
};
</script>

<style scoped lang="scss"></style>
