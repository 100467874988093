<template>
  <div class="position-relative full-page dance-stand-bg">
    <fullpageInfo></fullpageInfo>
    <div class="panel-back"></div>
    <rule :rules="rules"></rule>
  </div>
</template>

<script>
import fullpageInfo from "@/views/part/fullpageInfo";
import rule from "@/views/part/rule";

export default {
  name: "danceRule",
  data() {
    return {
      rules: [
        {
          title: "<h1 class='text-white text-center'>DANCE BATTLE</h1>",
          content: "<p>不要只會出一張嘴，來BATTLE呀，讓我們看看誰最會跳！最會跳的人才有資格教小桃！</p>"
        },
        {
          title: "<h3>Step1. 選擇角色</h3>",
          content:
            "<p>BATTLE須進行至少兩輪，2-3人一組，其中一人當『舞者』，第二人則選擇拿手機播放『展示影片』給『舞者』看。<br/><br/></p><div class='text-center'><img src='/img/cks-rule1.png' alt=''></div>"
        },
        {
          title: "<h3>【 三人行也可以！】</h3>",
          content:
            "<p>第三人選擇『舞者』或『展示影片』都可以。別急，下一輪可交替角色！<br/><br/><br/></p><div class='text-center'><img src='/img/cks-rule1-1.jpg' alt=''></div>"
        },
        {
          title: "<h3>Step2. 跳起來</h3>",
          content:
            "<p>『舞者』將手機緊握在<strong>右手</strong>，看『展示影片』的內容，當影片音樂開始後，跟著影片裡舞蹈老師的動作跳舞、直到影片結束<br/><br/></p><div class='text-center'><img src='/img/cks-rule2.png' alt=''></div>"
        },
        {
          title: "<h3>Step3. 再來一遍</h3>",
          content:
            "<p>該輪跳舞遊戲結束後，『舞者』和『展示影片』可互換角色重新選擇，不滿意上一輪分數的玩家也可以再次當『舞者』重跳一遍，挑戰更高分！</p><div class='text-center'><img src='/img/cks-rule3.png' alt=''></div>"
        },
        {
          title: "<h3>Step4. 獲勝條件</h3>",
          content:
            "<p>玩家可不斷挑戰自己的最高紀錄。最後只有分數最高的玩家才有資格教小桃跳舞！如果剛好同分的話就交由運氣，隨機決定最後的優勝啦！</p><div class='text-center'><img src='/img/cks-rule2.png' alt=''></div>"
        },
        {
          component: "danceRuleVideo"
        },
        {
          component: "danceRuleEnd"
        }
      ]
    };
  },
  components: {
    fullpageInfo,
    rule
  }
};
</script>

<style lang="scss">
.dance-stand-bg {
  .rule-panel {
    h1 {
      padding-top: 30px;
      font-size: 35px;
    }

    p {
      color: #fff;
      font-size: 15px;
      strong {
        color: #4285f4;
      }
    }
    img {
      max-width: 220px;
    }
    video {
      max-width: 220px;
    }
    span {
      color: #c64c72;
    }
  }
}

/*.rule-slides h4 {*/
/*font-size: 27px;*/
/*font-weight: bold;*/
/*color: #dbf2ff;*/
/*text-shadow: 0 3px 2px rgba(0, 0, 0, 0.16);*/
/*}*/
</style>
