<template>
  <div class="position-relative full-page dance-stand-bg">
    <FavorContainer
      v-if="flag('stage_2_danceFinish') && flag('stage_2_dance_bonus') == false"
      @close="close"
      :stage="stage"
      :step="1"
      :maximum_step="3"
    ></FavorContainer>
    <fullpageInfo></fullpageInfo>
    <resultList :title="title" :rankList="scores"></resultList>
    <div v-if="!isLeader" class="text-white position-absolute hint-text">請等待隊長操作..</div>
    <button v-if="isLeader && !flag('stage_2_danceFinish')" class="btn btn-primary btn-block mb-3" @click="standby">
      再跳一次
    </button>
    <button
      v-if="!flag('stage_2_danceFinish') && dance_times >= 2 && isLeader"
      class="btn btn-primary btn-block mb-3 mt-2"
      @click="danceOver"
    >
      結束練舞結算分數
    </button>
    <button
      role="button"
      v-if="flag('stage_2_danceFinish')"
      class="btn btn-primary btn-block mb-3 text-white"
      @click="leave"
    >
      離開
    </button>
  </div>
</template>

<script>
import fullpageInfo from "@/views/part/fullpageInfo";
import resultList from "@/views/part/resultList";
import WebSocketMixin from "../Mixins/WebSocketMixin";
import UserFromVuex from "../Mixins/UserFromVuex";
import CharacterFromVuex from "../Mixins/CharacterFromVuex";
import TeamFromVuex from "../Mixins/TeamFromVuex";
import StageFromMixin from "../Mixins/StageFromMixin";
import { mapActions } from "vuex";
import { sendMessage } from "../utils/WebSocket";
import Avatar from "@/Mixins/Avatar";
import FavorContainer from "@/views/favor/FavorContainer";
import FavorFromMixin from "@/Mixins/FavorFromMixin";

export default {
  name: "dacneResult",
  mixins: [WebSocketMixin, UserFromVuex, CharacterFromVuex, TeamFromVuex, StageFromMixin, Avatar, FavorFromMixin],
  components: {
    fullpageInfo,
    resultList,
    FavorContainer
  },
  computed: {
    dance_times() {
      return this.team_payload?.dance?.times ?? 0;
    },
    scores() {
      if (this?.team_payload?.score_board) {
        let list = Object.values(this?.team_payload?.score_board);
        if (list) {
          list = list.map(d => {
            let character = this.character_map.find(d2 => d2.user_id == d.id);
            d.name = character?.character?.name;
            d.avatar = this.getAvatar(d);
            return d;
          });
        }
        return list || [];
      }
      return [];
    },
    winners() {
      let max_score = Math.max(...this.scores.map(d => d.highscore));
      return this.scores.filter(d => d.highscore == max_score);
    }
  },
  data() {
    return {
      title: "舞林高手是誰",
      stage: {
        stage_id: 3
      },
      list: [
        {
          name: "小敏",
          nickname: "deathhell",
          picture: "girl1.png",
          score: 30
        },
        {
          name: "赤木",
          nickname: "happy",
          picture: "",
          score: 20
        }
      ]
    };
  },
  methods: {
    ...mapActions("Stage", ["gotoLocation"]),
    standby() {
      const data = {
        method: "EnterStage",
        token: this.token,
        location: {
          link: "danceStandBy"
        }
      };
      sendMessage(data);
    },
    danceOver() {
      if (this.flag("stage_2_danceFinish")) {
        return;
      }
      if (confirm("確定要結束battle嗎？ 之後就不能再跳了歐") == false) {
        return;
      }

      let winner = this.winners[0];
      let winner_character = this.character_map.find(d => d.user_id == winner.id);
      let winner_user = this.members.find(d => d.user_id === winner.id).user;
      this.setWinner({
        winner: winner_character.character,
        user: winner_user,
        stage: this.stage,
        step: 4
      });

      this.addScoreByRate({
        score: 20,
        user_id: winner.id,
        characterName: winner_character.character,
        isMoveStage: true,
        no_hint: true
      });
      this.setFlag({ key: "stage_2_danceWinner", status: winner_character.character });

      this.setFlag({ key: "stage_2_danceFinish", status: true });
      const data = {
        method: "EnterStage",
        token: this.token,
        location: {
          link: "cks"
        }
      };
      sendMessage(data);
    },
    close() {
      this.setFlag({ key: "stage_2_dance_bonus", status: true });
    },
    leave() {
      this.$router.replace("cks");
    }
  }
};
</script>

<style scoped lang="scss">
.full-page {
  padding: 0 30px;
}
</style>
