<template>
  <div class="position-relative full-page dance-bg overflow-hidden"
       @click="()=>{flag('touchable') && rhythmJudge()}">
    <scoreBoard :score="score"></scoreBoard>
    <danceBar :percent="percent"></danceBar>
    <a
      v-if="!checkMotionEvent"
      @click="po"
      class="position-fixed page-btn d-flex justify-content-center align-items-center"
    >
      開始遊戲
    </a>
    <a
      v-if="showEscape"
      @click="endgame"
      class="position-fixed page-btn d-flex justify-content-center align-items-center"
    >
      看結果
    </a>
    <!-- <button class="btn btn-primary position-fixed permission-btn btn-sm" @click="po">開啟權限</button> -->
    <!--<span v-text="max"></span>-->
    <div class="cha-circle circle position-absolute">
      <img v-bind:src="showAvatar(user.avatar)" alt="" />
    </div>
    <div class="position-absolute character-display">
      <img v-bind:src="showAvatar(character.figure)" alt="" />
    </div>
    <div class="position-absolute dance-state" :class="judge">
      <transition :duration="300" enter-active-class="bounceIn" leave-active-class="bounceOut">
        <img
          v-if="judge == 'perfect-state'"
          class="animated position-absolute dance-text"
          src="img/dance-perfect.png"
          alt=""
        />
      </transition>
      <transition :duration="300" enter-active-class="bounceIn" leave-active-class="bounceOut">
        <img
          v-if="judge == 'good-state'"
          class="animated position-absolute dance-text"
          src="img/dance-good.png"
          alt=""
        />
      </transition>
      <transition :duration="300" enter-active-class="bounceIn" leave-active-class="bounceOut">
        <img v-if="judge == 'ok-state'" class="animated position-absolute dance-text" src="img/dance-ok.png" alt="" />
      </transition>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import scoreBoard from "@/views/part/scoreBoard";
import danceBar from "@/views/part/danceBar";
import CharacterFromVuex from "../Mixins/CharacterFromVuex";
import UserFromVuex from "../Mixins/UserFromVuex";
import Avatar from "../Mixins/Avatar";
import { sendMessage } from "../utils/WebSocket";
import TeamFromVuex from "../Mixins/TeamFromVuex";
import WebSocketMixin from "../Mixins/WebSocketMixin";
import throttle from "lodash.throttle";
import ModalMixin from "../Mixins/ModalMixin";
import { power, setDeviceMotionEvent } from "../utils/Motion";
import { mapGetters } from "vuex";
import { getTimestampM } from "@/libs/time";
import HowlAudio from "@/Mixins/HowlAudio";
import { Howler } from "howler";

const gate = 20;

export default {
  name: "dance",
  mixins: [CharacterFromVuex, UserFromVuex, Avatar, TeamFromVuex, WebSocketMixin, ModalMixin, HowlAudio],
  components: {
    scoreBoard,
    danceBar
  },
  mounted() {
    this.table = Array.from(require("../data/dance.json")).map(d => {
      d.got = false;
      return d;
    });
    this.okAudio = this.makeAudio("/audio/dance-ok.mp3");
    this.goodAudio = this.makeAudio("/audio/dance-good.mp3");
    this.perfectAudio = this.makeAudio("/audio/dance-perfect.mp3");
    this.showEscapeTimeout = setTimeout(() => {
      this.showEscape = true;
    }, 80000);
    this.$once("hook:beforeDestroy", () => {
      if (this.timeout) {
        clearInterval(this.timeout);
      }
      clearTimeout(this.showEscapeTimeout);
      window.removeEventListener("devicemotion", this.checkDance);
    });
  },
  watch: {
    stage_2_danceFinish(val){
      if(val){
        this.$router.replace('danceResult');
      }
    },
    combo(val) {
      if (val > this.max_combo) {
        this.max_combo = val;
      }
    },
    play_time(val) {
      if (val >= 65000) {
        this.endgame();
      }
    },
    team_payload: {
      deep: true,
      handler(val) {
        if (val?.end === true) {
          this.endgame();
          return;
        }
        if (this.start_time === null) {
          if (val?.time > 0) {
            this.start_time = val.time;
            this.begin();
          }
        }
      }
    }
  },
  data() {
    return {
      combo: 0,
      max_combo: 0,
      max: 0,
      table: [],
      last_step: 0,
      score: 0,
      percent: 0,
      judge: "",
      state: 0,
      local_time: 0,
      start_time: null,
      power_list: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      checkMotionEvent: false,
      showEscape: false,
      showEscapeTimeout: null,
      okAudio: null,
      goodAudio: null,
      perfectAudio: null
    };
  },
  computed: {
    ...mapGetters("Dance", ["start_timestamp"]),
    ...mapGetters("Stage", ["flag"]),
    player_time() {
      return this?.team_payload?.time ?? this.start_time;
    },
    play_time() {
      if (this.start_timestamp) {
        return this.local_time - this.start_timestamp;
      }
      return 0;
    },
    perfect_step() {
      return this.good_step.filter(this.count_rule(100));
    },
    good_step() {
      return this.ok_step.filter(this.count_rule(200));
    },
    ok_step() {
      return this.table.filter(d => !d?.got).filter(this.count_rule(300));
    },
    stage_2_danceFinish(){
      return this.flag('stage_2_danceFinish');
    }
  },
  methods: {
    count_rule(gap = 100) {
      return d => {
        let gate = this.play_time - d.time * 1000;
        if (gate < 0) return false;
        return gate <= gap;
      };
    },
    begin() {
      this.start_time = getTimestampM();
      this.timeout = setInterval(this.timer, 50);
    },
    timer() {
      this.local_time = getTimestampM();
    },
    checkDance(event) {
      let power_value = power(event);
      this.power_list.push(power_value);
      this.power_list.shift();

      let max = Math.max(...this.power_list);
      this.max = max;
      if (max > gate) {
        this.dance();
      }
    },
    po() {
      this.checkMotionEvent = true;
      setDeviceMotionEvent(this.checkDance, console.log);
    },
    gotStep(step) {
      const index = this.table.findIndex(d => d.time == step.time);

      if (index !== -1) {
        let row = this.table[index];
        row.got = true;
        Vue.set(this.table, index, row);
      }
    },

    dance: throttle(function() {
      if (this.perfect_step.length > 0) {
        this.state = 1;
        this.gotStep(this.perfect_step[0]);
        this.doState();
        return;
      }
      if (this.good_step.length > 0) {
        this.state = 2;
        this.gotStep(this.good_step[0]);
        this.doState();
        return;
      }
      if (this.ok_step.length > 0) {
        this.state = 3;
        this.gotStep(this.ok_step[0]);
        this.doState();
        return;
      }

      this.combo = 0;
    }, 100),
    makeAudioAndUnload(src) {
      let audio = this.makeAudio(src);
      audio.play();
    },
    playAudio(state) {
      switch (state) {
        case "ok":
          // this.makeAudioAndUnload("/audio/dance-ok.mp3")
          this.okAudio.seek(0).play();
          break;
        case "perfect":
          // this.makeAudioAndUnload("/audio/dance-perfect.mp3");
          this.perfectAudio.seek(0).play();
          break;
        case "good":
          // this.makeAudioAndUnload("/audio/dance-good.mp3");
          this.goodAudio.seek(0).play();
          break;
        default:
          this.okAudio.play();
      }
    },
    doState() {
      switch (this.state) {
        case 0:
          this.judge = "";
          this.combo = 0;
          break;
        case 1:
          this.playAudio("perfect");
          this.judge = "perfect-state";
          this.score += 100;
          this.combo += 1;
          break;
        case 2:
          this.playAudio("good");
          this.judge = "good-state";
          this.score += 60;
          this.combo += 1;
          break;
        case 3:
          this.playAudio("ok");
          this.judge = "ok-state";
          this.score += 20;
          this.combo += 1;
          break;
      }
      setTimeout(() => (this.judge = ""), 600);
    },

    rhythmJudge: throttle(function() {
      if (this.local_time === 0) {
        this.begin();
        return;
      }
      this.dance();
    }, 200),
    endgame() {
      clearInterval(this.timeout);
      let messageData = {
        method: "DanceScore",
        token: this.token,
        score: this.score,
        max_combo: this.max_combo
      };
      sendMessage(messageData);
      this.$router.replace("/danceResult");
    }
  }
};
</script>

<style scoped lang="scss">
@import "~animate.css/source/_base.css";
@import "~animate.css/source/bouncing_entrances/bounceIn.css";
@import "~animate.css/source/bouncing_exits/bounceOut.css";

.full-page.dance-bg {
  background: url("/img/dance-bg.png") center center;
  background-size: cover;
}

.permission-btn {
  left: 10px;
  top: 100px;
}

.score-block {
  background: #1a1a1a;
  color: #fff;
}

.cha-circle {
  right: 30px;
  top: 40vh;
  background: #fff;
  z-index: 10;
}

.character-display {
  bottom: -20vh;
  height: 100vh;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.dance-state {
  left: 0;
  right: 0;
  bottom: 0;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;

  &.perfect-state {
    background: linear-gradient(to bottom, rgba(196, 154, 58, 0) 50%, rgba(196, 154, 58, 0.8));
  }

  &.good-state {
    background: linear-gradient(to bottom, rgba(36, 162, 209, 0) 50%, rgba(36, 162, 209, 0.8));
  }

  &.ok-state {
    background: linear-gradient(to bottom, rgba(196, 36, 209, 0) 50%, rgba(196, 36, 209, 0.8));
  }
}
</style>
